.dataTables_wrapper {
  .row {
    margin: auto 0;
  }

  .dataTables_length,
  .dataTables_filter {
    label {
      margin-bottom: 10px;
      color: #888;
      select {
        margin: auto 5px;
      }
    }
  }

  &.form-inline {
    display: block;
  }

  table {
    &.dataTable {
      width: 100% !important;
      td,
      th {
        padding: 1.05rem 0.75rem;
      }

      thead {
        th {
          border-bottom: 1px solid #eaeaea;
        }
      }

      tfoot {
        th {
          border-top: 1px solid #eaeaea;
        }
      }

      &.dtr-inline.collapsed > tbody > tr[role='row'] > td:first-child:before,
      .dtr-inline.collapsed > tbody > tr[role='row'] > th:first-child:before {
        top: 18px;
      }
    }
  }

  .dataTables_paginate {
    .paginate_button {
      border: 1px solid #dee2e6;
      border-radius: 0;
      margin-left: 0;
      min-width: auto;

      &.disabled {
        border-color: #dee2e6;
        &:hover {
          border-color: #dee2e6;
          background-color: #fff;
        }
        a {
          color: #6c757d;
          cursor: auto;
        }
      }

      &:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
      &:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
      &:hover {
        border-color: #dee2e6;
        background: #e9ecef;
      }

      &.active {
        background: #04a9f5;
        border-color: #04a9f5;
        a {
          color: #fff;
        }
        &:hover {
          background: #04a9f5;
          border-color: #04a9f5;
        }
      }
    }
  }

  .DTFC_ScrollWrapper {
    .DTFC_RightHeadWrapper,
    .DTFC_RightBodyWrapper,
    .DTFC_RightFootWrapper,
    .DTFC_LeftHeadWrapper,
    .DTFC_LeftBodyWrapper,
    .DTFC_LeftFootWrapper {
      background-color: #ffffff;
    }
  }
}
table.dataTable thead .sorting {
  width: auto !important;
  min-width: auto !important;
}
